import { XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

export default function MessageBanner({
  color,
  message,
  onClose,
  introString,
}: {
  color: string;
  message: JSX.Element;
  onClose?: () => void;
  introString: string;
}) {
  const bgColor = "bg-" + color + "-50";
  const textColor = "text-" + color + "-";
  const textColor400 = textColor + "400";
  const textColor700 = textColor + "700";
  const textColor800 = textColor + "800";
  return (
    <div className={`rounded-md ${bgColor} p-4 mb-6`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon
            className={`h-5 w-5 ${textColor400}`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${textColor800}`}>
            {introString}
          </h3>
          <div className={`mt-2 text-sm ${textColor700}`}>{message}</div>
        </div>
        {onClose ? (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
