/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query getTranscriptForStory($storyId: String!) {\n    getTranscriptForStory(storyId: $storyId) {\n      id\n      textContent\n      speakerRole\n      speakerType\n      createdAt\n    }\n  }\n": types.GetTranscriptForStoryDocument,
    "\n  subscription subMessagesByStory($storyId: String!) {\n    subMessagesByStory(storyId: $storyId) {\n      id\n      textContent\n      speakerRole\n      speakerType\n      createdAt\n    }\n  }\n": types.SubMessagesByStoryDocument,
    "\n  mutation AuthUser(\n    $displayName: String!\n    $email: String!\n    $token: String!\n    $uid: String!\n    $photoUrl: String\n  ) {\n    authUser(\n      displayName: $displayName\n      email: $email\n      token: $token\n      uid: $uid\n      photoUrl: $photoUrl\n    )\n  }\n": types.AuthUserDocument,
    "\n  query GetUserBiography($userId: String!) {\n    getUserBiography(userId: $userId)\n  }\n": types.GetUserBiographyDocument,
    "\n  query getStory($storyId: String!) {\n    getStory(storyId: $storyId) {\n      id\n      name\n      createdAt\n      updatedAt\n    }\n  }\n": types.GetStoryDocument,
    "\n  query getAudioChunksForStory($storyId: String!) {\n    getAudioChunksForStory(storyId: $storyId)\n  }\n": types.GetAudioChunksForStoryDocument,
    "\n  mutation AddStory($botId: String!, $userId: String!, $name: String!) {\n    addStory(botId: $botId, userId: $userId, name: $name) {\n      id\n    }\n  }\n": types.AddStoryDocument,
    "\n  query GetBots {\n    bots {\n      id\n      displayName\n    }\n  }\n": types.GetBotsDocument,
    "\n  query GetStoriesForUser($userId: String!) {\n    getStoriesForUser(userId: $userId) {\n      id\n      name\n      createdAt\n      updatedAt\n      botId\n      finalized\n      public\n      longSummary\n      shortSummary\n    }\n  }\n": types.GetStoriesForUserDocument,
    "\n  mutation endStory($storyId: String!) {\n    endStory(storyId: $storyId)\n  }\n": types.EndStoryDocument,
    "\n  mutation AddStoryRequest(\n    $senderId: String!\n    $email: String!\n    $greeting: String!\n    $suggestedTopics: String!\n  ) {\n    addStoryRequestByEmail(\n      senderId: $senderId\n      email: $email\n      greeting: $greeting\n      suggestedTopics: $suggestedTopics\n    )\n  }\n": types.AddStoryRequestDocument,
    "\n  query getStoryRequest($requestId: String!) {\n    getStoryRequest(id: $requestId) {\n      id\n      sender {\n        id\n        displayName\n      }\n      receiver {\n        id\n        displayName\n      }\n      greeting\n      receiverEmail\n      suggestedTopics\n      accepted\n      rejected\n      interview {\n        id\n      }\n      acceptedAt\n      createdAt\n    }\n  }\n": types.GetStoryRequestDocument,
    "\n  mutation respondStoryRequest($requestId: String!, $accept: Boolean!, $botId: String) {\n    handleStoryRequestOperation(requestId: $requestId, accept: $accept, botId: $botId)\n  }\n": types.RespondStoryRequestDocument,
    "\n  query getStoryRequestsForUser($userId: String!) {\n    getStoryRequestsForUser(userId: $userId) {\n      id\n      sender {\n        id\n        displayName\n      }\n      receiver {\n        id\n        displayName\n      }\n      greeting\n      receiverEmail\n      suggestedTopics\n      accepted\n      rejected\n      interview {\n        id\n      }\n      acceptedAt\n      createdAt\n    }\n  }\n  ": types.GetStoryRequestsForUserDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getTranscriptForStory($storyId: String!) {\n    getTranscriptForStory(storyId: $storyId) {\n      id\n      textContent\n      speakerRole\n      speakerType\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query getTranscriptForStory($storyId: String!) {\n    getTranscriptForStory(storyId: $storyId) {\n      id\n      textContent\n      speakerRole\n      speakerType\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription subMessagesByStory($storyId: String!) {\n    subMessagesByStory(storyId: $storyId) {\n      id\n      textContent\n      speakerRole\n      speakerType\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  subscription subMessagesByStory($storyId: String!) {\n    subMessagesByStory(storyId: $storyId) {\n      id\n      textContent\n      speakerRole\n      speakerType\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AuthUser(\n    $displayName: String!\n    $email: String!\n    $token: String!\n    $uid: String!\n    $photoUrl: String\n  ) {\n    authUser(\n      displayName: $displayName\n      email: $email\n      token: $token\n      uid: $uid\n      photoUrl: $photoUrl\n    )\n  }\n"): (typeof documents)["\n  mutation AuthUser(\n    $displayName: String!\n    $email: String!\n    $token: String!\n    $uid: String!\n    $photoUrl: String\n  ) {\n    authUser(\n      displayName: $displayName\n      email: $email\n      token: $token\n      uid: $uid\n      photoUrl: $photoUrl\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUserBiography($userId: String!) {\n    getUserBiography(userId: $userId)\n  }\n"): (typeof documents)["\n  query GetUserBiography($userId: String!) {\n    getUserBiography(userId: $userId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStory($storyId: String!) {\n    getStory(storyId: $storyId) {\n      id\n      name\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query getStory($storyId: String!) {\n    getStory(storyId: $storyId) {\n      id\n      name\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAudioChunksForStory($storyId: String!) {\n    getAudioChunksForStory(storyId: $storyId)\n  }\n"): (typeof documents)["\n  query getAudioChunksForStory($storyId: String!) {\n    getAudioChunksForStory(storyId: $storyId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddStory($botId: String!, $userId: String!, $name: String!) {\n    addStory(botId: $botId, userId: $userId, name: $name) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AddStory($botId: String!, $userId: String!, $name: String!) {\n    addStory(botId: $botId, userId: $userId, name: $name) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBots {\n    bots {\n      id\n      displayName\n    }\n  }\n"): (typeof documents)["\n  query GetBots {\n    bots {\n      id\n      displayName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetStoriesForUser($userId: String!) {\n    getStoriesForUser(userId: $userId) {\n      id\n      name\n      createdAt\n      updatedAt\n      botId\n      finalized\n      public\n      longSummary\n      shortSummary\n    }\n  }\n"): (typeof documents)["\n  query GetStoriesForUser($userId: String!) {\n    getStoriesForUser(userId: $userId) {\n      id\n      name\n      createdAt\n      updatedAt\n      botId\n      finalized\n      public\n      longSummary\n      shortSummary\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation endStory($storyId: String!) {\n    endStory(storyId: $storyId)\n  }\n"): (typeof documents)["\n  mutation endStory($storyId: String!) {\n    endStory(storyId: $storyId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddStoryRequest(\n    $senderId: String!\n    $email: String!\n    $greeting: String!\n    $suggestedTopics: String!\n  ) {\n    addStoryRequestByEmail(\n      senderId: $senderId\n      email: $email\n      greeting: $greeting\n      suggestedTopics: $suggestedTopics\n    )\n  }\n"): (typeof documents)["\n  mutation AddStoryRequest(\n    $senderId: String!\n    $email: String!\n    $greeting: String!\n    $suggestedTopics: String!\n  ) {\n    addStoryRequestByEmail(\n      senderId: $senderId\n      email: $email\n      greeting: $greeting\n      suggestedTopics: $suggestedTopics\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStoryRequest($requestId: String!) {\n    getStoryRequest(id: $requestId) {\n      id\n      sender {\n        id\n        displayName\n      }\n      receiver {\n        id\n        displayName\n      }\n      greeting\n      receiverEmail\n      suggestedTopics\n      accepted\n      rejected\n      interview {\n        id\n      }\n      acceptedAt\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query getStoryRequest($requestId: String!) {\n    getStoryRequest(id: $requestId) {\n      id\n      sender {\n        id\n        displayName\n      }\n      receiver {\n        id\n        displayName\n      }\n      greeting\n      receiverEmail\n      suggestedTopics\n      accepted\n      rejected\n      interview {\n        id\n      }\n      acceptedAt\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation respondStoryRequest($requestId: String!, $accept: Boolean!, $botId: String) {\n    handleStoryRequestOperation(requestId: $requestId, accept: $accept, botId: $botId)\n  }\n"): (typeof documents)["\n  mutation respondStoryRequest($requestId: String!, $accept: Boolean!, $botId: String) {\n    handleStoryRequestOperation(requestId: $requestId, accept: $accept, botId: $botId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getStoryRequestsForUser($userId: String!) {\n    getStoryRequestsForUser(userId: $userId) {\n      id\n      sender {\n        id\n        displayName\n      }\n      receiver {\n        id\n        displayName\n      }\n      greeting\n      receiverEmail\n      suggestedTopics\n      accepted\n      rejected\n      interview {\n        id\n      }\n      acceptedAt\n      createdAt\n    }\n  }\n  "): (typeof documents)["\n  query getStoryRequestsForUser($userId: String!) {\n    getStoryRequestsForUser(userId: $userId) {\n      id\n      sender {\n        id\n        displayName\n      }\n      receiver {\n        id\n        displayName\n      }\n      greeting\n      receiverEmail\n      suggestedTopics\n      accepted\n      rejected\n      interview {\n        id\n      }\n      acceptedAt\n      createdAt\n    }\n  }\n  "];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;