import React from "react";

import { useAuth } from "providers/UserProvider";
import Login from "components/Login";
import { Flex, Box, Container } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

export default function SignIn(): JSX.Element {
  const { user } = useAuth();
  const navigate = useNavigate();
  // const { trackEvent: track } = useAnalytics();
  if (user) {
    navigate("/stories");
  }
  return (
    <div className="min-h-screen">
      <div className="relative overflow-hidden">
        <main>
          <div className="min-h-screen">
            <div className="bg-[conic-gradient(at_right,_var(--tw-gradient-stops))] from-indigo-200 via-red-200 to-yellow-100 min-h-screen">
              <Container size="1" p={"1"}>
                <Flex gap="5" direction={"column"} align={"center"}>
                  <Box>
                    {!user && (
                      <div className="mt-4">
                        <Login text="Login or signup" />
                      </div>
                    )}
                  </Box>
                </Flex>
              </Container>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
