import React, { createContext, useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebase";
import { User, onAuthStateChanged } from "firebase/auth";

type UserContextType = {
  user: User | null;
  loading: boolean;
};

const UserContext = createContext<UserContextType | undefined>(undefined);
UserContext.displayName = "UserContext";

type UserProviderProps = {
  children: React.ReactNode;
};

function UserProvider({ children }: UserProviderProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const authStateChange = onAuthStateChanged(auth, (result) => {
      setUser(result);
      setLoading(false);
    });
    return authStateChange;
  }, [navigate, location.pathname]);

  const value = { user, loading };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useAuth() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a UserProvider");
  }

  const signOut = () => {
    if (context.user) {
      auth.signOut();
    }
  };

  const { user, loading } = context;
  return { user, loading, signOut };
}

export { UserProvider, useAuth };
