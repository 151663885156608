import { Card, Container, Heading, Link, Flex } from "@radix-ui/themes";

// TODO: write an actual opt out page
export default function OptOut() {
  return (
    <div className="min-h-screen">
      <Container size="1" p={{ initial: "3", md: "3" }}>
        <Card>
          <Flex gap="3" direction={"column"}>
            <Heading my={"3"}>Opt Out</Heading>
            <p>
              Memoir takes your privacy seriously. We only send emails to users
              provided by other users, and monitor usage for potential misuse.
            </p>
            <p>
              If you would like to opt out of receiving Memoir requests, please
              email us at{" "}
              <Link href="mailto:optout@talkmemoir.com">
                optout@talkmemoir.com
              </Link>
              .
            </p>
          </Flex>
        </Card>
      </Container>
    </div>
  );
}
