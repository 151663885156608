import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { GetStoryRequestsForUserQuery } from "__generated__/graphql";
import { useAuth } from "providers/UserProvider";

import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Heading,
  Section,
  Text,
} from "@radix-ui/themes";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { timeSinceString } from "helpers/prettyTime";

const GET_STORY_REQUESTS_QUERY = gql(`
  query getStoryRequestsForUser($userId: String!) {
    getStoryRequestsForUser(userId: $userId) {
      id
      sender {
        id
        displayName
      }
      receiver {
        id
        displayName
      }
      greeting
      receiverEmail
      suggestedTopics
      accepted
      rejected
      interview {
        id
      }
      acceptedAt
      createdAt
    }
  }
  `);

interface InterviewRequestCardProps {
  request: GetStoryRequestsForUserQuery["getStoryRequestsForUser"][0];
  isSent: boolean;
}

function InterviewRequest({ request, isSent }: InterviewRequestCardProps) {
  const navigate = useNavigate();
  // renders a display for a single interview request
  return (
    <Card size={"3"}>
      <Flex gap="1" direction="column" justify="between">
        <Box id="content">
          {!isSent && (
            <>
              <Text as="div" size="2" color="gray">
                From
              </Text>
              <p>{request.sender.displayName}</p>
            </>
          )}
          {isSent && (
            <>
              <Text as="div" size="2" color="gray">
                To
              </Text>
              <p>{request.receiver?.displayName || request.receiverEmail}</p>
            </>
          )}

          <Text as="div" size="2" color="gray">
            Greeting
          </Text>
          <p>{request.greeting}</p>
          <Text as="div" size="2" color="gray">
            Suggested topics
          </Text>

          <p>{request.suggestedTopics}</p>
          <p>{request.accepted}</p>
          <p>{request.interview?.id}</p>
          {request.acceptedAt && <p>{request.acceptedAt}</p>}
          <Text size="2" color="gray">
            Created {timeSinceString(request.createdAt)}
          </Text>
        </Box>

        {/* should be bottom of flex */}
        <Box height={"3"} pb="2" width={"100%"}>
          <Box
            id="actions"
            position={"absolute"}
            bottom={"0"}
            pb={"2"}
            width={"100%"}
          >
            <Flex gap="1" justify="between">
              <Box>
                <Button
                  variant="soft"
                  onClick={() => {
                    navigate(`/requests/${request.id}`);
                  }}
                >
                  View Request
                </Button>
              </Box>
              {request.interview && (
                <Box>
                  <Button variant="soft">
                    <RouterLink to={`/stories/${request.interview.id}`}>
                      View Interview
                    </RouterLink>
                  </Button>
                </Box>
              )}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Card>
  );
}

export default function Requests() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_STORY_REQUESTS_QUERY, {
    // @ts-ignore
    variables: { userId: user?.uid },
    skip: !user,
  });

  if (!user) {
    navigate("/login");
  }

  return (
    <div className="min-h-screen">
      <Container size="3" p={{ initial: "3", md: "3" }}>
        <Flex mb={"3"} mt={"3"} gap="3" direction={"row"} justify={"between"}>
          <Heading size="5" align={"left"}>
            Story Requests
          </Heading>
          <Button
            onClick={() => {
              navigate(`/requests/new`);
            }}
          >
            <RouterLink to="/requests/new">New Request</RouterLink>
          </Button>
        </Flex>

        {error && <p>{error.message}</p>}
        <Section>
          <Heading size={"2"} mb={"2"}>
            Sent
          </Heading>
          {loading && <p>Loading...</p>}
          {data && (
            <Grid columns={{ initial: "2", md: "3" }} gap="3">
              {data.getStoryRequestsForUser
                .filter(
                  (
                    request: GetStoryRequestsForUserQuery["getStoryRequestsForUser"][0]
                  ) => request.sender?.id === user?.uid
                )
                .map(
                  (
                    request: GetStoryRequestsForUserQuery["getStoryRequestsForUser"][0]
                  ) => (
                    <InterviewRequest
                      request={request}
                      isSent={true}
                      key={request.id}
                    />
                  )
                )}
            </Grid>
          )}
        </Section>
        <Section>
          <Heading size={"2"} mb={"2"}>
            Received
          </Heading>
          {loading && <p>Loading...</p>}
          {data && (
            <Grid columns={{ initial: "2", md: "3" }} gap="3">
              {data.getStoryRequestsForUser
                .filter(
                  (
                    request: GetStoryRequestsForUserQuery["getStoryRequestsForUser"][0]
                  ) => request.receiver?.id === user?.uid
                )
                .map(
                  (
                    request: GetStoryRequestsForUserQuery["getStoryRequestsForUser"][0]
                  ) => (
                    <InterviewRequest
                      request={request}
                      isSent={false}
                      key={request.id}
                    />
                  )
                )}
            </Grid>
          )}
        </Section>
      </Container>
    </div>
  );
}
