import { auth } from "../helpers/firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useAnalytics } from "providers/AnalyticsProvider";
import { Card, Button, Flex, Heading } from "@radix-ui/themes";
import { FaGoogle } from "react-icons/fa";

function Login({ text }: { text: string }) {
  const { trackEvent: track } = useAnalytics();
  const handleGoogleLogin = async () => {
    track("Login", "click", "googleLogin");
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        track("Login", "success", "googleLogin");
        track("Login", "user", result?.user?.uid);
      })
      .catch((error) => {
        track("Login", "error", "googleLogin");
        console.log(error);
      });
  };

  return (
    <Card size={"3"}>
      <Flex gap="3" direction={"column"} align={"center"}>
        <Heading size="3" align={"center"}>
          {text}
        </Heading>
        <Button onClick={handleGoogleLogin} size={"3"} className="w-full">
          <FaGoogle className="h-full" />
          <span>Login with Google</span>
        </Button>
      </Flex>
    </Card>
  );
}

export default Login;
