import React, { Fragment, useEffect, useState } from "react";
import { useConversation } from "taolun";
import { useNavigate, useParams } from "react-router-dom";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { gql, useQuery, useMutation } from "@apollo/client";
import Transcript from "components/Transcript";

import { useAuth } from "../providers/UserProvider";

import { PageLoading, StartInterviewFailMessage } from "../components";
import { useAnalytics } from "providers/AnalyticsProvider";
import {
  Container,
  Flex,
  Box,
  Heading,
  Card,
  Grid,
  IconButton,
  Dialog,
  Button,
} from "@radix-ui/themes";
import { FaGear } from "react-icons/fa6";

enum InterviewStatuses {
  INTERVIEWING = "INTERVIEWING",
  PAUSED = "PAUSED",
  WAITING = "WAITING",
  FINALIZED = "FINALIZED",
}

const STORY_DETAILS_QUERY = gql(`
  query getStory($storyId: String!) {
    getStory(storyId: $storyId) {
      id
      name
      createdAt
      updatedAt
    }
  }
`);

const END_INTERVIEW_MUTATION = gql(`
  mutation endStory($storyId: String!) {
    endStory(storyId: $storyId)
  }
`);

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function EndInterviewButton({
  setInterviewStatus,
  iid,
  track,
  stop,
}: {
  setInterviewStatus: (status: InterviewStatuses) => void;
  iid: string | undefined;
  track: (category: string, action: string, label: string) => void;
  stop: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const [endInterviewMutation, { loading }] = useMutation(
    END_INTERVIEW_MUTATION
  );
  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    track("StoryInterview", "click", "endInterview");

    // returns a boolean indicating whether the story was ended successfully
    const result = await endInterviewMutation({
      // @ts-ignore
      variables: { storyId: iid },
    });

    if (result?.data?.endStory) {
      track("StoryInterview", "endInterview", "success");
      setInterviewStatus(InterviewStatuses.WAITING);
      await stop();
      navigate("/stories/" + iid + "/final");
    } else {
      track("StoryInterview", "endInterview", "failure");
    }
  };

  return (
    <Button onClick={onClick} disabled={loading} size={"3"} color="red">
      {loading ? "Loading..." : "End"}
    </Button>
  );
}

type Topic = {
  topic: string;
  id: string;
};

const fakeTopics: Topic[] = [
  {
    topic: "Childhood",
    id: "childhood",
  },
  {
    topic: "Family",
    id: "family",
  },
  {
    topic: "Career",
    id: "career",
  },
];

function SelectNextTopics({ iid }: { iid: string | undefined }) {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [selected, setSelected] = useState<Topic>(fakeTopics[0]);

  useEffect(() => {
    setTopics(fakeTopics);
  }, []);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-center leading-6 text-gray-900">
            Pick Next Topic
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block">{selected.topic}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {topics.map((topic) => (
                  <Listbox.Option
                    key={topic.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={topic}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {topic.topic}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

function Interview({
  iid,
  interviewStatus,
  setInterviewStatus,
  start,
  stop,
  track,
}: {
  iid: string | undefined;
  interviewStatus: InterviewStatuses;
  setInterviewStatus: (status: InterviewStatuses) => void;
  start: () => Promise<void>;
  stop: () => void;
  track: (category: string, action: string, label: string) => void;
}) {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const isInterviewingOrPaused =
    interviewStatus === InterviewStatuses.INTERVIEWING ||
    interviewStatus === InterviewStatuses.PAUSED;
  const transcript = (
    <Transcript
      iid={iid}
      doSubscribe={true}
      setIsSubscribed={setIsSubscribed}
    />
  );

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isSubscribed) {
      return;
    }

    track("StoryInterview", "click", "startInterview");

    try {
      start();
      setInterviewStatus(InterviewStatuses.INTERVIEWING);
      track("StoryInterview", "result", "success");
    } catch (e) {
      track("StoryInterview", "result", "error");
      console.log(e);
    }
  };

  return (
    <div className="flex flex-col gap-y-5 min-w-full h-full">
      {transcript}
      <Grid columns={{ initial: "1", md: "2" }} gap="3">
        <Flex justify={"between"}>
          {isInterviewingOrPaused && (
            <EndInterviewButton
              iid={iid}
              setInterviewStatus={setInterviewStatus}
              track={track}
              // @ts-ignore -- fixing in fork
              stop={stop}
            />
          )}
          {!isInterviewingOrPaused && (
            <Button size={"4"} onClick={onClick}>
              Start Interview
            </Button>
          )}
        </Flex>
        <Box>
          <SelectNextTopics iid={iid} />
        </Box>
      </Grid>
    </div>
  );
}

function SelectDeviceForm({
  inputDevices,
  outputDevices,
  selectedInputDevice,
  selectedOutputDevice,
  setSelectedInputDevice,
  setSelectedOutputDevice,
}: {
  inputDevices: MediaDeviceInfo[];
  outputDevices: MediaDeviceInfo[];
  selectedInputDevice: string | undefined;
  selectedOutputDevice: string | undefined;
  setSelectedInputDevice: (deviceId: string) => void;
  setSelectedOutputDevice: (deviceId: string) => void;
}) {
  return (
    <Grid columns={{ initial: "1", md: "2" }} gap="3">
      <Box>
        Input devices (microphones):
        <select
          value={selectedInputDevice}
          className="w-full"
          onChange={(e) => setSelectedInputDevice(e.target.value)}
        >
          {inputDevices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          ))}
        </select>
      </Box>
      <Box>
        Output devices (speakers):
        <select
          value={selectedOutputDevice}
          className="w-full"
          onChange={(e) => setSelectedOutputDevice(e.target.value)}
        >
          {outputDevices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          ))}
        </select>
      </Box>
    </Grid>
  );
}

export default function StoryInterview() {
  const { user, loading } = useAuth();
  const { storyId } = useParams();
  const navigate = useNavigate();
  if (!user) {
    navigate("/login");
  }

  const { trackEvent: track } = useAnalytics();
  const [selectedOutputDevice, setSelectedOutputDevice] = useState<
    string | undefined
  >();
  const [selectedInputDevice, setSelectedInputDevice] = useState<
    string | undefined
  >();
  const [inputDevices, setInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [outputDevices, setOutputDevices] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    // have to ask permission first on FF
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(() =>
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            setInputDevices(
              devices.filter(
                (device) => device.deviceId && device.kind === "audioinput"
              )
            );
            setOutputDevices(
              devices.filter(
                (device) => device.deviceId && device.kind === "audiooutput"
              )
            );
          })
          .catch((err) => {
            console.error(err);
          })
      )
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const config = {
    backendUrl: process.env.REACT_APP_BACKEND_URL || "",
    // we handle transcript on backend
    subscribeTranscript: false,
    conversationId: storyId,
  };
  const audioDeviceConfig = {
    inputDeviceId: selectedInputDevice,
    outputDeviceId: selectedOutputDevice,
  };
  const { start, stop, error } = useConversation(
    Object.assign(config, { audioDeviceConfig })
  );

  if (error) {
    console.error("conversation error", error);
  }

  const [interviewStatus, setInterviewStatus] = useState(
    InterviewStatuses.WAITING
  );
  const [startInterviewError, setStartInterviewError] = useState(null);

  const {
    loading: storyDetailsLoading,
    error: storyDetailsError,
    data: storyDetailsData,
  } = useQuery(STORY_DETAILS_QUERY, {
    // @ts-ignore
    variables: { storyId: storyId },
    skip: !storyId,
  });

  if (!user || loading) {
    return (
      <div className="min-h-screen">
        <div className="flex w-screen h-full px-2">
          <div className="m-auto">
            <PageLoading />
          </div>
        </div>
      </div>
    );
  }

  // TODO: replace with 500 page
  if (storyDetailsError) {
    return (
      <div className="min-h-screen">
        <div className="flex w-screen h-full px-2">
          <div className="m-auto">
            <p>{storyDetailsError.message}</p>
          </div>
        </div>
      </div>
    );
  }

  // TODO: replace with 404 page
  if (!storyDetailsLoading && !storyDetailsData?.getStory) {
    return (
      <div className="min-h-screen">
        <div className="flex w-screen h-full px-2">
          <div className="m-auto">
            <p>Story not found</p>
          </div>
        </div>
      </div>
    );
  }

  const content = (
    <Interview
      iid={storyId}
      interviewStatus={interviewStatus}
      setInterviewStatus={setInterviewStatus}
      // @ts-ignore -- fixing in fork
      start={start}
      stop={stop}
      track={track}
    />
  );

  let banner;
  if (startInterviewError) {
    banner = (
      <StartInterviewFailMessage
        info={startInterviewError}
        onClose={() => {}}
      />
    );
  } else {
    banner = null;
  }

  const interviewName = storyDetailsData?.getStory?.name || "Interview";

  return (
    <div className="min-h-screen">
      <Container size={{ initial: "1", md: "3" }} p={{ initial: "3", md: "3" }}>
        <Card>
          <div>{banner}</div>
          {storyDetailsLoading && <PageLoading />}
          <Flex direction={"column"} justify={"between"}>
            <Box>
              {storyDetailsData && (
                <>
                  <Flex direction={"row"} gap="3" align="center">
                    <Heading>{interviewName}</Heading>
                    {/* <span>Created: {storyDetailsData.getStory.createdAt}</span> */}
                    <Dialog.Root>
                      <Dialog.Trigger>
                        <IconButton>
                          <FaGear />
                        </IconButton>
                      </Dialog.Trigger>
                      <Dialog.Content>
                        <Dialog.Title>Audio settings</Dialog.Title>
                        <Dialog.Description size="2" mb="4">
                          Choose input and output devices.
                        </Dialog.Description>
                        <SelectDeviceForm
                          inputDevices={inputDevices}
                          outputDevices={outputDevices}
                          selectedInputDevice={selectedInputDevice}
                          selectedOutputDevice={selectedOutputDevice}
                          setSelectedInputDevice={setSelectedInputDevice}
                          setSelectedOutputDevice={setSelectedOutputDevice}
                        />
                        <Flex gap="3" mt="4" justify="end">
                          <Dialog.Close>
                            <Button>Save</Button>
                          </Dialog.Close>
                        </Flex>
                      </Dialog.Content>
                    </Dialog.Root>
                  </Flex>
                </>
              )}
            </Box>
            <Box mt={"2"}>{content}</Box>
          </Flex>
        </Card>
      </Container>
    </div>
  );
}
