/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Bot = {
  __typename?: 'Bot';
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  voiceId?: Maybe<Scalars['String']['output']>;
};

export type GetBotInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Message = {
  __typename?: 'Message';
  audioSlug?: Maybe<Scalars['String']['output']>;
  botId?: Maybe<Scalars['String']['output']>;
  convoHash?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  speakerRole: SpeakerRole;
  speakerType: SpeakerType;
  storyId: Scalars['String']['output'];
  textContent?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addBot: Bot;
  addStory: Story;
  addStoryRequestByEmail: Scalars['String']['output'];
  authUser: Scalars['Boolean']['output'];
  endStory: Scalars['Boolean']['output'];
  handleStoryRequestOperation?: Maybe<Scalars['String']['output']>;
};


export type MutationAddBotArgs = {
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
};


export type MutationAddStoryArgs = {
  botId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddStoryRequestByEmailArgs = {
  email: Scalars['String']['input'];
  greeting: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
  suggestedTopics: Scalars['String']['input'];
};


export type MutationAuthUserArgs = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationEndStoryArgs = {
  storyId: Scalars['String']['input'];
};


export type MutationHandleStoryRequestOperationArgs = {
  accept: Scalars['Boolean']['input'];
  botId?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  bots: Array<Bot>;
  getAudioChunksForStory: Array<Scalars['String']['output']>;
  getBot?: Maybe<Bot>;
  getMessage?: Maybe<Message>;
  getStoriesForUser: Array<Story>;
  getStory?: Maybe<Story>;
  getStoryRequest?: Maybe<StoryRequest>;
  getStoryRequestsForUser: Array<StoryRequest>;
  getTranscriptForStory: Array<Message>;
  getUser?: Maybe<User>;
  getUserBiography?: Maybe<Scalars['String']['output']>;
  messages: Array<Message>;
  stories: Array<Story>;
  users: Array<User>;
};


export type QueryGetAudioChunksForStoryArgs = {
  storyId: Scalars['String']['input'];
};


export type QueryGetBotArgs = {
  inp: GetBotInput;
};


export type QueryGetMessageArgs = {
  messageId: Scalars['String']['input'];
};


export type QueryGetStoriesForUserArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetStoryArgs = {
  storyId: Scalars['String']['input'];
};


export type QueryGetStoryRequestArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetStoryRequestsForUserArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetTranscriptForStoryArgs = {
  storyId: Scalars['String']['input'];
};


export type QueryGetUserArgs = {
  inp: GetUserInput;
};


export type QueryGetUserBiographyArgs = {
  userId: Scalars['String']['input'];
};

export enum SpeakerRole {
  Interviewer = 'INTERVIEWER',
  None = 'NONE',
  Subject = 'SUBJECT'
}

export enum SpeakerType {
  Bot = 'BOT',
  Human = 'HUMAN'
}

export type Story = {
  __typename?: 'Story';
  botId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  finalized: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lastSummaryMessageCreatedAt?: Maybe<Scalars['String']['output']>;
  longSummary?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  requestId?: Maybe<Scalars['String']['output']>;
  requesterId?: Maybe<Scalars['String']['output']>;
  shortSummary?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type StoryRequest = {
  __typename?: 'StoryRequest';
  accepted: Scalars['Boolean']['output'];
  acceptedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  greeting: Scalars['String']['output'];
  id: Scalars['String']['output'];
  interview?: Maybe<Story>;
  receiver?: Maybe<User>;
  receiverEmail?: Maybe<Scalars['String']['output']>;
  receiverName?: Maybe<Scalars['String']['output']>;
  rejected: Scalars['Boolean']['output'];
  sender: User;
  suggestedTopics: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  subMessagesByStory: Message;
};


export type SubscriptionSubMessagesByStoryArgs = {
  storyId: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  biography: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  photoUrl?: Maybe<Scalars['String']['output']>;
};

export type GetTranscriptForStoryQueryVariables = Exact<{
  storyId: Scalars['String']['input'];
}>;


export type GetTranscriptForStoryQuery = { __typename?: 'Query', getTranscriptForStory: Array<{ __typename?: 'Message', id: string, textContent?: string | null, speakerRole: SpeakerRole, speakerType: SpeakerType, createdAt: string }> };

export type SubMessagesByStorySubscriptionVariables = Exact<{
  storyId: Scalars['String']['input'];
}>;


export type SubMessagesByStorySubscription = { __typename?: 'Subscription', subMessagesByStory: { __typename?: 'Message', id: string, textContent?: string | null, speakerRole: SpeakerRole, speakerType: SpeakerType, createdAt: string } };

export type AuthUserMutationVariables = Exact<{
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
  photoUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type AuthUserMutation = { __typename?: 'Mutation', authUser: boolean };

export type GetUserBiographyQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserBiographyQuery = { __typename?: 'Query', getUserBiography?: string | null };

export type GetStoryQueryVariables = Exact<{
  storyId: Scalars['String']['input'];
}>;


export type GetStoryQuery = { __typename?: 'Query', getStory?: { __typename?: 'Story', id: string, name: string, createdAt: string, updatedAt: string } | null };

export type GetAudioChunksForStoryQueryVariables = Exact<{
  storyId: Scalars['String']['input'];
}>;


export type GetAudioChunksForStoryQuery = { __typename?: 'Query', getAudioChunksForStory: Array<string> };

export type AddStoryMutationVariables = Exact<{
  botId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type AddStoryMutation = { __typename?: 'Mutation', addStory: { __typename?: 'Story', id: string } };

export type GetBotsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBotsQuery = { __typename?: 'Query', bots: Array<{ __typename?: 'Bot', id: string, displayName: string }> };

export type GetStoriesForUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetStoriesForUserQuery = { __typename?: 'Query', getStoriesForUser: Array<{ __typename?: 'Story', id: string, name: string, createdAt: string, updatedAt: string, botId: string, finalized: boolean, public: boolean, longSummary?: string | null, shortSummary?: string | null }> };

export type EndStoryMutationVariables = Exact<{
  storyId: Scalars['String']['input'];
}>;


export type EndStoryMutation = { __typename?: 'Mutation', endStory: boolean };

export type AddStoryRequestMutationVariables = Exact<{
  senderId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  greeting: Scalars['String']['input'];
  suggestedTopics: Scalars['String']['input'];
}>;


export type AddStoryRequestMutation = { __typename?: 'Mutation', addStoryRequestByEmail: string };

export type GetStoryRequestQueryVariables = Exact<{
  requestId: Scalars['String']['input'];
}>;


export type GetStoryRequestQuery = { __typename?: 'Query', getStoryRequest?: { __typename?: 'StoryRequest', id: string, greeting: string, receiverEmail?: string | null, suggestedTopics: string, accepted: boolean, rejected: boolean, acceptedAt?: string | null, createdAt: string, sender: { __typename?: 'User', id: string, displayName: string }, receiver?: { __typename?: 'User', id: string, displayName: string } | null, interview?: { __typename?: 'Story', id: string } | null } | null };

export type RespondStoryRequestMutationVariables = Exact<{
  requestId: Scalars['String']['input'];
  accept: Scalars['Boolean']['input'];
  botId?: InputMaybe<Scalars['String']['input']>;
}>;


export type RespondStoryRequestMutation = { __typename?: 'Mutation', handleStoryRequestOperation?: string | null };

export type GetStoryRequestsForUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetStoryRequestsForUserQuery = { __typename?: 'Query', getStoryRequestsForUser: Array<{ __typename?: 'StoryRequest', id: string, greeting: string, receiverEmail?: string | null, suggestedTopics: string, accepted: boolean, rejected: boolean, acceptedAt?: string | null, createdAt: string, sender: { __typename?: 'User', id: string, displayName: string }, receiver?: { __typename?: 'User', id: string, displayName: string } | null, interview?: { __typename?: 'Story', id: string } | null }> };


export const GetTranscriptForStoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getTranscriptForStory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getTranscriptForStory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"storyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"textContent"}},{"kind":"Field","name":{"kind":"Name","value":"speakerRole"}},{"kind":"Field","name":{"kind":"Name","value":"speakerType"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<GetTranscriptForStoryQuery, GetTranscriptForStoryQueryVariables>;
export const SubMessagesByStoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"subMessagesByStory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subMessagesByStory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"storyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"textContent"}},{"kind":"Field","name":{"kind":"Name","value":"speakerRole"}},{"kind":"Field","name":{"kind":"Name","value":"speakerType"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<SubMessagesByStorySubscription, SubMessagesByStorySubscriptionVariables>;
export const AuthUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"displayName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"uid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"photoUrl"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"displayName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"displayName"}}},{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}},{"kind":"Argument","name":{"kind":"Name","value":"uid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"uid"}}},{"kind":"Argument","name":{"kind":"Name","value":"photoUrl"},"value":{"kind":"Variable","name":{"kind":"Name","value":"photoUrl"}}}]}]}}]} as unknown as DocumentNode<AuthUserMutation, AuthUserMutationVariables>;
export const GetUserBiographyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserBiography"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserBiography"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}]}]}}]} as unknown as DocumentNode<GetUserBiographyQuery, GetUserBiographyQueryVariables>;
export const GetStoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getStory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getStory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"storyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]} as unknown as DocumentNode<GetStoryQuery, GetStoryQueryVariables>;
export const GetAudioChunksForStoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAudioChunksForStory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAudioChunksForStory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"storyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}}}]}]}}]} as unknown as DocumentNode<GetAudioChunksForStoryQuery, GetAudioChunksForStoryQueryVariables>;
export const AddStoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddStory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"botId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addStory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"botId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"botId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddStoryMutation, AddStoryMutationVariables>;
export const GetBotsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetBots"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bots"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]} as unknown as DocumentNode<GetBotsQuery, GetBotsQueryVariables>;
export const GetStoriesForUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetStoriesForUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getStoriesForUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"botId"}},{"kind":"Field","name":{"kind":"Name","value":"finalized"}},{"kind":"Field","name":{"kind":"Name","value":"public"}},{"kind":"Field","name":{"kind":"Name","value":"longSummary"}},{"kind":"Field","name":{"kind":"Name","value":"shortSummary"}}]}}]}}]} as unknown as DocumentNode<GetStoriesForUserQuery, GetStoriesForUserQueryVariables>;
export const EndStoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"endStory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"endStory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"storyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"storyId"}}}]}]}}]} as unknown as DocumentNode<EndStoryMutation, EndStoryMutationVariables>;
export const AddStoryRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddStoryRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"senderId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"greeting"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"suggestedTopics"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addStoryRequestByEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"senderId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"senderId"}}},{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"greeting"},"value":{"kind":"Variable","name":{"kind":"Name","value":"greeting"}}},{"kind":"Argument","name":{"kind":"Name","value":"suggestedTopics"},"value":{"kind":"Variable","name":{"kind":"Name","value":"suggestedTopics"}}}]}]}}]} as unknown as DocumentNode<AddStoryRequestMutation, AddStoryRequestMutationVariables>;
export const GetStoryRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getStoryRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getStoryRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sender"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"receiver"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"greeting"}},{"kind":"Field","name":{"kind":"Name","value":"receiverEmail"}},{"kind":"Field","name":{"kind":"Name","value":"suggestedTopics"}},{"kind":"Field","name":{"kind":"Name","value":"accepted"}},{"kind":"Field","name":{"kind":"Name","value":"rejected"}},{"kind":"Field","name":{"kind":"Name","value":"interview"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"acceptedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<GetStoryRequestQuery, GetStoryRequestQueryVariables>;
export const RespondStoryRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"respondStoryRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"accept"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"botId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"handleStoryRequestOperation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"requestId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestId"}}},{"kind":"Argument","name":{"kind":"Name","value":"accept"},"value":{"kind":"Variable","name":{"kind":"Name","value":"accept"}}},{"kind":"Argument","name":{"kind":"Name","value":"botId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"botId"}}}]}]}}]} as unknown as DocumentNode<RespondStoryRequestMutation, RespondStoryRequestMutationVariables>;
export const GetStoryRequestsForUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getStoryRequestsForUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getStoryRequestsForUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sender"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"receiver"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"greeting"}},{"kind":"Field","name":{"kind":"Name","value":"receiverEmail"}},{"kind":"Field","name":{"kind":"Name","value":"suggestedTopics"}},{"kind":"Field","name":{"kind":"Name","value":"accepted"}},{"kind":"Field","name":{"kind":"Name","value":"rejected"}},{"kind":"Field","name":{"kind":"Name","value":"interview"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"acceptedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]} as unknown as DocumentNode<GetStoryRequestsForUserQuery, GetStoryRequestsForUserQueryVariables>;