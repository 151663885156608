import React, { createContext, useEffect, useState, ReactNode } from "react";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { User } from "firebase/auth";

import { useAuth } from "./UserProvider";

interface AuthUserData {
  authUser: boolean;
}

interface Payload {
  token: string;
  uid: string;
  email: string | null;
  displayName: string | null;
  photoUrl: string | null;
}

const AUTH_USER_MUTATION = gql(`
  mutation AuthUser(
    $displayName: String!
    $email: String!
    $token: String!
    $uid: String!
    $photoUrl: String
  ) {
    authUser(
      displayName: $displayName
      email: $email
      token: $token
      uid: $uid
      photoUrl: $photoUrl
    )
  }
`);

const USER_BIOGRAPHY_QUERY = gql(`
  query GetUserBiography($userId: String!) {
    getUserBiography(userId: $userId)
  }
`);

interface UserBackendContextValue {
  authorized: boolean;
  user: User | null;
  userBiography: string | null;
}

const UserBackendContext = createContext<UserBackendContextValue | undefined>(
  undefined
);
UserBackendContext.displayName = "FirebaseContext";

interface UserBackendProviderProps {
  children: ReactNode;
}

function UserBackendProvider({
  children,
}: UserBackendProviderProps): JSX.Element {
  const { user } = useAuth();
  const [userMutation] = useMutation<AuthUserData>(AUTH_USER_MUTATION);
  const [userBioQuery, { data: bioData }] = useLazyQuery(USER_BIOGRAPHY_QUERY);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (!user) {
      setAuthorized(false);
      return;
    }

    user.getIdToken().then(async (token) => {
      // send token to backend
      if (!token) {
        console.warn("UserBackendProvider: no token");
        return;
      }
      const payload: Payload = {
        token: token,
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoUrl: user.photoURL,
      };
      const { data } = await userMutation({ variables: payload });
      if (data && data.authUser) {
        setAuthorized(true);
        userBioQuery({ variables: { userId: user.uid } });
      } else {
        console.warn("Authorization failed");
        setAuthorized(false);
      }
    });
  }, [user, userBioQuery, userMutation]);

  const value: UserBackendContextValue = {
    authorized,
    user,
    userBiography: bioData?.getUserBiography || null,
  };

  return (
    <UserBackendContext.Provider value={value}>
      {children}
    </UserBackendContext.Provider>
  );
}

function useAuthedUser() {
  const context = React.useContext(UserBackendContext);
  if (context === undefined) {
    throw new Error("useAuthedUser must be used within a UserBackendProvider");
  }

  const authorized = context.authorized;
  const user = context.user;
  const userBiography = context.userBiography;
  return { authorized, user, userBiography };
}

export { UserBackendProvider, useAuthedUser };
