import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { useNavigate } from "react-router-dom";
import { useAuth } from "providers/UserProvider";
import {
  AlertDialog,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  Flex,
  Text,
} from "@radix-ui/themes";
import { Login } from "components";
import { GetStoryRequestQuery } from "__generated__/graphql";
import { User } from "firebase/auth";

const GET_STORY_REQUEST_QUERY = gql(`
  query getStoryRequest($requestId: String!) {
    getStoryRequest(id: $requestId) {
      id
      sender {
        id
        displayName
      }
      receiver {
        id
        displayName
      }
      greeting
      receiverEmail
      suggestedTopics
      accepted
      rejected
      interview {
        id
      }
      acceptedAt
      createdAt
    }
  }
`);

const GET_BOTS_QUERY = gql(`
  query GetBots {
    bots {
      id
      displayName
    }
  }
`);

// handleStoryRequestOperation(requestId: String!, accept: Boolean!, botId: String): String

const RESPOND_STORY_REQUEST_MUTATION = gql(`
  mutation respondStoryRequest($requestId: String!, $accept: Boolean!, $botId: String) {
    handleStoryRequestOperation(requestId: $requestId, accept: $accept, botId: $botId)
  }
`);

function RequestCard({
  loading,
  error,
  data,
  user,
}: {
  loading: boolean;
  error: Error | undefined;
  data: GetStoryRequestQuery | undefined;
  user: User | null;
}) {
  const [accepting, setAccepting] = useState(false);
  const [botId, setBotId] = useState("");
  const navigate = useNavigate();

  const { data: botsData } = useQuery(GET_BOTS_QUERY);

  // this function determines if the current user is authorized to handle this request
  function isAuthorizedForRequest() {
    if (!user) {
      return false;
    }
    if (data && data.getStoryRequest) {
      if (
        data.getStoryRequest.receiver &&
        data.getStoryRequest.receiver.id === user.uid
      ) {
        return true;
      }
      if (data.getStoryRequest.receiverEmail === user.email) {
        return true;
      }
    }
    return false;
  }

  // true if the request has no receiver ID
  function isClaimable() {
    if (data && data.getStoryRequest) {
      return !data.getStoryRequest.receiver;
    }
    return false;
  }

  function isntHandledYet() {
    if (data && data.getStoryRequest) {
      return !(data.getStoryRequest.accepted || data.getStoryRequest.rejected);
    }
    return false;
  }

  const request = data?.getStoryRequest;
  const [respondRequest] = useMutation(RESPOND_STORY_REQUEST_MUTATION, {
    variables: {
      // @ts-ignore
      requestId: data?.getStoryRequest?.id,
      accept: accepting,
      botId: botId,
    },
    skip: !data?.getStoryRequest?.id,
  });

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }
  if (!request) {
    return <p>Request not found</p>;
  }

  return (
    <Card>
      <Flex gap="1" direction="column" justify="between">
        <Box id="content">
          <Text as="div" size="2" color="gray">
            {request.sender.displayName} to{" "}
            {request.receiver?.displayName || request.receiverEmail}
          </Text>
          <Text as="div" size="2" color="gray">
            {request.greeting}
          </Text>
        </Box>
        <Box>
          {!user && isClaimable() && (
            <div>
              <Login text="Sign in to accept or reject this request" />
            </div>
          )}
          {user && isAuthorizedForRequest() && isntHandledYet() && (
            <Flex gap="1" direction="column">
              <Flex justify={"between"}>
                <div>
                  <Dialog.Root>
                    <Dialog.Trigger>
                      <Button>Accept</Button>
                    </Dialog.Trigger>

                    <Dialog.Content style={{ maxWidth: 450 }}>
                      <Dialog.Title>Accept request</Dialog.Title>
                      <Dialog.Description size="2" mb="4">
                        Finalize interview settings
                      </Dialog.Description>

                      <Flex direction="column" gap="3">
                        <label htmlFor="botId">Interviewer Bot</label>
                        <select
                          id="botId"
                          value={botId}
                          onChange={(e) => {
                            setBotId(e.target.value);
                          }}
                        >
                          {botsData?.bots.map((bot) => {
                            return (
                              <option key={bot.id} value={bot.id}>
                                {bot.displayName}
                              </option>
                            );
                          })}
                        </select>
                      </Flex>

                      <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                          <Button variant="soft" color="gray">
                            Cancel
                          </Button>
                        </Dialog.Close>
                        <Dialog.Close>
                          <Button
                            onClick={() => {
                              setAccepting(true);
                              respondRequest({
                                variables: {
                                  accept: true,
                                  botId: botId,
                                  // @ts-ignore
                                  requestId: data?.getStoryRequest?.id,
                                },
                              }).then((acceptedRequestData) => {
                                navigate(
                                  "/stories/" +
                                    acceptedRequestData.data
                                      ?.handleStoryRequestOperation
                                );
                              });
                            }}
                          >
                            Accept
                          </Button>
                        </Dialog.Close>
                      </Flex>
                    </Dialog.Content>
                  </Dialog.Root>
                </div>
                <div>
                  <AlertDialog.Root>
                    <AlertDialog.Trigger>
                      <Button color="red">Reject</Button>
                    </AlertDialog.Trigger>
                    <AlertDialog.Content style={{ maxWidth: 450 }}>
                      <AlertDialog.Title>Reject request</AlertDialog.Title>
                      <AlertDialog.Description size="2">
                        Are you sure? You won't be able to undo this action.
                      </AlertDialog.Description>

                      <Flex gap="3" mt="4" justify="end">
                        <AlertDialog.Cancel>
                          <Button variant="soft" color="gray">
                            Cancel
                          </Button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Action>
                          <Button
                            variant="solid"
                            color="red"
                            onClick={() => {
                              respondRequest({
                                variables: {
                                  // @ts-ignore
                                  requestId: data?.getStoryRequest?.id,
                                  accept: false,
                                },
                              }).then(() => {
                                navigate("/requests");
                              });
                            }}
                          >
                            Reject
                          </Button>
                        </AlertDialog.Action>
                      </Flex>
                    </AlertDialog.Content>
                  </AlertDialog.Root>
                </div>
              </Flex>
            </Flex>
          )}
        </Box>
      </Flex>
    </Card>
  );
}

export default function Request() {
  const { user } = useAuth();
  const { requestId } = useParams();
  const { loading, error, data } = useQuery(GET_STORY_REQUEST_QUERY, {
    // @ts-ignore
    variables: { requestId },
    skip: !requestId,
  });

  return (
    <div className="min-h-screen">
      <Container size="1" p={{ initial: "3", md: "3" }}>
        <RequestCard loading={loading} error={error} data={data} user={user} />
      </Container>
    </div>
  );
}
