import React from "react";
import MessageBanner from "./MessageBanner";

export default function StartInterviewFailMessage({
  info,
  onClose,
}: {
  info: string;
  onClose?: () => void;
}): JSX.Element {
  const introString = "Interview Start Fail!";
  const color = "yellow";
  const message = <p className="text-sm">{info}</p>;
  return (
    <MessageBanner
      color={color}
      introString={introString}
      message={message}
      onClose={onClose}
    />
  );
}
