import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { app } from "./helpers/firebase";
import {
  Landing,
  StoryInterview,
  NewStoryInterview,
  Stories,
  CompletedStory,
  SignIn,
  OptOut,
} from "./views";
import { Requests, NewRequest, Request } from "./views/requests";
import { UserProvider } from "./providers/UserProvider";
import { GraphqlProvider } from "./providers/GraphqlProvider";
import { UserBackendProvider } from "./providers/UserBackendProvider";
import { AnalyticsProvider } from "./providers/AnalyticsProvider";
import { Header, Footer } from "./components";
import "./App.css";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      document.title = "Memoir (🚧)";
    }
  }, []);

  const analyticsTrackingId = app.options.measurementId || "";

  return (
    <AnalyticsProvider
      analyticsTrackingId={analyticsTrackingId}
      initialPath={pathname}
    >
      <UserProvider>
        <GraphqlProvider>
          <UserBackendProvider>
            <Header />
            <main>
              <Routes>
                <Route index path="/" element={<Landing />} />
                <Route path="/login" element={<SignIn />} />
                {/* story logic */}
                <Route path="/stories" element={<Stories />} />
                <Route path="/stories/new" element={<NewStoryInterview />} />
                <Route path="/stories/:storyId" element={<StoryInterview />} />
                {/* TODO: we should route around this... */}
                <Route
                  path="/stories/:storyId/final"
                  element={<CompletedStory />}
                />
                {/* request logic */}
                <Route path="/requests" element={<Requests />} />
                <Route path="/requests/new" element={<NewRequest />} />
                <Route path="/requests/:requestId" element={<Request />} />
                {/* opt out */}
                <Route path="/opt-out" element={<OptOut />} />
              </Routes>
            </main>
            <Footer />
          </UserBackendProvider>
        </GraphqlProvider>
      </UserProvider>
    </AnalyticsProvider>
  );
}

export default App;
