import { useAuth } from "providers/UserProvider";
import Login from "components/Login";
import {
  Heading,
  Flex,
  Grid,
  Box,
  Container,
  Text,
  Section,
  Button,
  Card,
  Inset,
} from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

const FeatureBox = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <Card>
    <Inset my={"4"}>
      <Heading size="5" align={"center"}>
        {title}
      </Heading>
    </Inset>
    <Text>{description}</Text>
  </Card>
);

export default function Landing(): JSX.Element {
  const { user } = useAuth();
  // const { trackEvent: track } = useAnalytics();
  const navigate = useNavigate();
  // if (user) {
  //   navigate("/stories");
  // }

  return (
    <div className="min-h-screen">
      <div className="bg-[conic-gradient(at_right,_var(--tw-gradient-stops))] from-indigo-200 via-red-200 to-yellow-100 min-h-screen">
        <Container size="3" p={"3"}>
          <Flex gap="5" direction={"column"} align={"center"}>
            <Box className="mt-4">
              <Heading
                size="9"
                color="indigo"
                align={"center"}
                as="h1"
                style={{ fontFamily: "Playfair Display" }}
              >
                Tell Your Story
              </Heading>
              <Heading
                size="7"
                align={"center"}
                className="mt-4"
                as="h2"
                style={{ fontFamily: "Playfair Display" }}
              >
                Capture, Chronicle, Celebrate.
              </Heading>
            </Box>
            <Section>
              <Flex direction={"column"} gap={"3"}>
                <Text size="4" align={"center"}>
                  Ever wondered what it would be like to be in the hot seat with
                  Larry King or Terry Gross? Memoir brings that experience to
                  you, your parents, and even your grandparents, making it
                  effortless to preserve legacies and share extraordinary tales.
                </Text>
                <Text size="4" align={"center"}>
                  Capture your unique journey or the incredible lives of your
                  loved ones with Memoir’s intuitive AI interviewer. It's not
                  just a recording; it’s an archive of memories, insights, and
                  wisdom that you can pass down for generations.
                </Text>
                <Text size="4" align={"center"}>
                  Don't let the memories fade away. Get started with Memoir
                  today, and keep your story alive for tomorrow.
                </Text>
              </Flex>
            </Section>
            <Section>
              {!user && (
                <div className="mt-4">
                  <Login text="Get started" />
                </div>
              )}
              {
                // TODO: logged in dashboard view
                user && (
                  <Card>
                    <Heading size="5" align={"center"} mb={"3"}>
                      Welcome back!
                    </Heading>
                    <Flex direction={"column"} gap={"4"}>
                      <Button
                        onClick={() => navigate("/stories")}
                        variant="outline"
                      >
                        Go to your stories
                      </Button>
                      <Button
                        onClick={() => navigate("/stories/new")}
                        variant="outline"
                      >
                        Start a new story
                      </Button>
                    </Flex>
                  </Card>
                )
              }
            </Section>
            <Section>
              <Grid columns={{ initial: "1", md: "3" }} gap="3">
                <FeatureBox
                  title="🤖 Record with a copilot"
                  description="Invite a bot to interview you. It will ask you questions that you can answer in your own time."
                />
                <FeatureBox
                  title="🎙️ Invite a friend"
                  description="Send a request to a friend to interview them. They'll get an email with a link to a bot that will ask them questions."
                />
                <FeatureBox
                  title="🎧 Listen to top stories (soon)"
                  description="Listen to the top stories from Memoir's community. Stories are always private by default, but you can make them public if you want."
                />
              </Grid>
            </Section>
          </Flex>
        </Container>
      </div>
    </div>
  );
}
