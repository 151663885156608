// GraphQLProvider.tsx
import React, { createContext, useEffect, useState } from "react";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  ApolloClient,
  InMemoryCache,
  split,
  HttpLink,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";

import { useAuth } from "./UserProvider";

type GraphqlContextType = {
  client: ApolloClient<NormalizedCacheObject>;
};

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTP_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_WS_URL || "",
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);
const cache = new InMemoryCache();
const defaultClient = new ApolloClient<NormalizedCacheObject>({
  link: splitLink,
  cache: cache,
});

const GraphqlContext = createContext<GraphqlContextType | undefined>(undefined);
GraphqlContext.displayName = "GraphqlContext";

type GraphqlProviderProps = {
  children: React.ReactNode;
};

function GraphqlProvider({ children }: GraphqlProviderProps): JSX.Element {
  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject>>(defaultClient);
  const [token, setToken] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }
    user.getIdToken().then((token) => setToken(token));
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });
    const gqlClient = new ApolloClient({
      link: authLink.concat(splitLink),
      cache: cache,
    });
    setClient(gqlClient);
  }, [user, token]);

  return (
    <GraphqlContext.Provider value={{ client }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </GraphqlContext.Provider>
  );
}

function useClient(): { client: ApolloClient<NormalizedCacheObject> } {
  const context = React.useContext(GraphqlContext);

  if (context === undefined) {
    throw new Error("useClient must be used within a GraphqlProvider");
  }

  const { client } = context;

  return { client };
}

export { GraphqlProvider, useClient };
