import React, { FormEvent, useState } from "react";

import { type ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { redirect, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/UserProvider";
import { PageLoading } from "../components";
import {
  Button,
  Container,
  Card,
  Callout,
  Text,
  Heading,
} from "@radix-ui/themes";
import { FaCircleExclamation } from "react-icons/fa6";

const NEW_STORY_MUTATION = gql(`
  mutation AddStory($botId: String!, $userId: String!, $name: String!) {
    addStory(botId: $botId, userId: $userId, name: $name) {
      id
    }
  }
`);

const GET_BOTS_QUERY = gql(`
  query GetBots {
    bots {
      id
      displayName
    }
  }
`);

function InterviewForm() {
  const { user, loading } = useAuth();
  const [topic, setTopic] = useState("");
  const [topics, setTopics] = useState<string[]>([]);
  const navigate = useNavigate();
  const [selectedInterviewer, setSelectedInterviewer] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [addingStory, setAddingStory] = useState<boolean>(false);

  const {
    loading: botsLoading,
    error: botsError,
    data: botsData,
  } = useQuery(GET_BOTS_QUERY);

  const [addStory, { error: newInterviewError }] =
    useMutation(NEW_STORY_MUTATION);

  const handleAddTopic = () => {
    if (topic) {
      setTopics([...topics, topic]);
      setTopic(""); // Reset the topic input field
    }
  };

  const handleRemoveTopic = (indexToRemove: number) => {
    setTopics(topics.filter((_, index) => index !== indexToRemove));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!user) {
      console.error("Must be logged in");
      setError("Must be logged in!");
      return;
    }
    const variables = {
      botId: selectedInterviewer,
      userId: user.uid,
      name: "New interview",
    };

    if (selectedInterviewer) {
      setError(null);
      try {
        setAddingStory(true);
        const result = await addStory({
          variables: variables,
        });
        setAddingStory(false);

        if (result.data) {
          navigate(`/stories/${result.data.addStory.id}`);
        }
      } catch (error: ApolloError | any) {
        setAddingStory(false);
        setError(error.message);
        console.error("Error adding story:", error);
      }
    } else {
      setError("Please select an interviewer and enter a title");
    }
  };

  if (!user) {
    redirect("/login");
  }

  if (!user || loading) {
    return (
      <div className="flex w-screen h-full px-2">
        <div className="m-auto">
          <PageLoading />
        </div>
      </div>
    );
  }

  return (
    <Card>
      <Heading>New Interview</Heading>
      {error && (
        <Callout.Root size="2" color="red">
          <Callout.Icon>
            <FaCircleExclamation />
          </Callout.Icon>
          <Callout.Text>{error}</Callout.Text>
        </Callout.Root>
      )}
      {newInterviewError && (
        <Callout.Root size="2" color="red">
          <Callout.Icon>
            <FaCircleExclamation />
          </Callout.Icon>
          <Callout.Text>{newInterviewError.message}</Callout.Text>
        </Callout.Root>
      )}
      {addingStory && (
        <Callout.Root size="2" color="blue">
          <Callout.Icon>
            <div
              className="
                    animate-spin
                    rounded-full
                    h-32
                    w-32
                    border-t-2 border-b-2 border-purple-500
                    "
            />
          </Callout.Icon>
          <Callout.Text>Adding story...</Callout.Text>
        </Callout.Root>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="interviewer"
            className="block text-sm font-medium text-gray-700"
          >
            Interviewer:
          </label>
          <select
            id="interviewer"
            value={selectedInterviewer}
            onChange={(e) => setSelectedInterviewer(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md"
          >
            {botsLoading && <option value="">Loading...</option>}
            {botsData && botsData.bots && botsData.bots.length === 0 && (
              <option value="">No interviewers available</option>
            )}
            {botsError && <option value="">Error loading interviewers</option>}
            {botsData && (
              <>
                <option value="">Select an interviewer</option>
                {botsData.bots.map(
                  ({
                    id,
                    displayName,
                  }: {
                    id: number;
                    displayName: String;
                  }) => (
                    <option key={id} value={id}>
                      {displayName}
                    </option>
                  )
                )}
              </>
            )}
          </select>
        </div>
        <div>
          <label
            htmlFor="topic"
            className="block text-sm font-medium text-gray-700"
          >
            Suggested Topic:
          </label>
          <div className="flex items-center mt-1">
            <input
              type="text"
              id="topic"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              className="p-2 flex-grow border rounded-md mr-2"
            />
            <Button
              onClick={handleAddTopic}
              className="p-2 bg-blue-500 text-white rounded"
              size="1"
              variant="soft"
            >
              +
            </Button>
          </div>
        </div>

        {topics.length > 0 && (
          <div>
            <h2 className="text-sm font-medium text-gray-700">Topics List:</h2>
            {topics.map((t, index) => (
              <div key={index} className="flex items-center">
                <Button
                  onClick={() => handleRemoveTopic(index)}
                  variant="soft"
                  color="red"
                  mr={"2"}
                  size={"1"}
                >
                  X
                </Button>
                <Text>{t}</Text>
              </div>
            ))}
          </div>
        )}

        <div>
          <Button type="submit" size={"3"}>
            Create
          </Button>
        </div>
      </form>
    </Card>
  );
}

export default function NewStoryInterview() {
  return (
    <div className="min-h-screen">
      <Container size="1" p={{ initial: "3", md: "3" }}>
        <InterviewForm />
      </Container>
    </div>
  );
}
