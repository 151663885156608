import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Theme
    accentColor="indigo"
    grayColor="gray"
    panelBackground="translucent"
    // https://github.com/radix-ui/themes/issues/22
    appearance="light"
    scaling="110%"
    radius="medium"
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Theme>,
);
