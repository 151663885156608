import { useState, FormEvent } from "react";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { redirect, useNavigate } from "react-router-dom";
import { useAuth } from "providers/UserProvider";
import { useAuthedUser } from "providers/UserBackendProvider";
import {
  Container,
  Card,
  Heading,
  Button,
  TextArea,
  TextFieldInput,
} from "@radix-ui/themes";
interface AddStoryRequestData {
  addStoryRequestByEmail: {
    id: string;
  };
}

const ADD_STORY_REQUEST = gql`
  mutation AddStoryRequest(
    $senderId: String!
    $email: String!
    $greeting: String!
    $suggestedTopics: String!
  ) {
    addStoryRequestByEmail(
      senderId: $senderId
      email: $email
      greeting: $greeting
      suggestedTopics: $suggestedTopics
    )
  }
`;

export default function NewRequest() {
  const { user } = useAuth();
  const { authorized } = useAuthedUser();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [greeting, setGreeting] = useState("");
  const [suggestedTopics, setSuggestedTopics] = useState("");
  const [error, setError] = useState<string | null>(null);

  const [addStoryRequest] = useMutation<AddStoryRequestData>(
    ADD_STORY_REQUEST,
    {
      onCompleted: (data) => {
        navigate(`/requests/${data.addStoryRequestByEmail}`);
      },
    }
  );

  if (!user) {
    redirect("/login");
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (user && authorized) {
      try {
        await addStoryRequest({
          variables: {
            senderId: user.uid,
            email,
            greeting,
            suggestedTopics,
          },
        });
      } catch (error: ApolloError | any) {
        console.error("Failed to create story request:", error);
        setError(error.message);
      }
    } else {
      console.error("User is not authorized");
      setError("User is not authorized");
    }
  };

  return (
    <div className="min-h-screen">
      <Container size="1" p={{ initial: "3", md: "3" }}>
        <Card>
          <Heading>New Story Request</Heading>
          {error && <p>{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                aria-required="true"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <TextFieldInput
                id="email"
                required
                type="email"
                placeholder="Email to request"
                className="mt-1 p-2 w-full border rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="greeting"
                aria-required="true"
                className="block text-sm font-medium text-gray-700"
              >
                Greeting
              </label>
              <TextArea
                id="greeting"
                required
                placeholder="Hey! I'd love to hear about..."
                value={greeting}
                onChange={(e) => setGreeting(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="suggestedTopics"
                className="block text-sm font-medium text-gray-700"
              >
                Suggested Topics
              </label>
              <TextFieldInput
                id="suggestedTopics"
                type="text"
                className="mt-1 p-2 w-full border rounded-md"
                value={suggestedTopics}
                onChange={(e) => setSuggestedTopics(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Button type="submit" size="3">
                Request
              </Button>
            </div>
          </form>
        </Card>
      </Container>
    </div>
  );
}
