import { useAnalytics } from "providers/AnalyticsProvider";
import { useAuthedUser } from "../providers/UserBackendProvider";
import { Text, Button, DropdownMenu, Link, Avatar } from "@radix-ui/themes";
import { FaCaretDown } from "react-icons/fa";
import { useAuth } from "providers/UserProvider";
import { Link as RouterLink, useNavigate } from "react-router-dom";

export default function Header() {
  const { trackEvent: track } = useAnalytics();

  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { authorized, user } = useAuthedUser();

  let navRight = (
    <Link asChild>
      <RouterLink to="/login">
        <Text className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100">
          Log in
        </Text>
      </RouterLink>
    </Link>
  );

  if (authorized) {
    const loggedInNavigation = [
      { type: "link", name: "Tell your story!", href: "/stories/new" },
      { type: "link", name: "View stories", href: "/stories/" },
      { type: "link", name: "Requests", href: "/requests/" },
    ];
    const userDisplayName = user?.displayName?.split(" ")[0] || "Account";

    const fallback = (
      <span className="text-white dark:text-gray-300">
        {userDisplayName[0]}
      </span>
    );

    navRight = (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Button variant="soft">
            <Avatar
              src={user?.photoURL || undefined}
              alt={userDisplayName}
              fallback={fallback}
              size={"1"}
              className="text-gray-600 dark:text-gray-300"
            />
            <span className="text-gray-600 dark:text-gray-300">
              {userDisplayName}
            </span>
            <FaCaretDown className="ml-2 text-gray-600 dark:text-gray-300" />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {loggedInNavigation.map((item) => {
            return (
              <RouterLink to={item.href} key={item.href}>
                <DropdownMenu.Item>{item.name}</DropdownMenu.Item>
              </RouterLink>
            );
          })}
          <DropdownMenu.Item
            onClick={() => {
              track("Logout", "user", user?.uid);
              signOut();
              navigate("/");
            }}
          >
            Logout
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  }

  return (
    <header>
      <nav className="bg-gray-100 border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <RouterLink to="/" className="flex items-center">
            <span
              className="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
              style={{ fontFamily: "Playfair Display" }}
            >
              📖 Memoir AI
            </span>
          </RouterLink>
          <div className="flex items-center lg:order-2">{navRight}</div>
        </div>
      </nav>
    </header>
  );
}
