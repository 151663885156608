import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import ReactGA from "react-ga";

export interface TrackEventFunction {
  (category: string, action: string, label: string | undefined): void;
}

export interface TrackPageViewFunction {
  (page: string): void;
}

export interface AnalyticsFunctions {
  trackEvent: TrackEventFunction;
  trackPageView: TrackPageViewFunction;
}

const AnalyticsContext = createContext<AnalyticsFunctions | null>(null);
AnalyticsContext.displayName = "AnalyticsContext";

interface AnalyticsProviderProps {
  analyticsTrackingId: string;
  initialPath: string;
  children: ReactNode;
}

export const AnalyticsProvider = ({
  children,
  analyticsTrackingId,
  initialPath,
}: AnalyticsProviderProps) => {
  const [gaTracker, setGATracker] = useState<AnalyticsFunctions | null>(null);
  useEffect(() => {
    if (analyticsTrackingId) {
      const analyticsDebug = process.env.DEBUG === "true";
      ReactGA.initialize(analyticsTrackingId, {
        debug: analyticsDebug,
        titleCase: false,
        gaOptions: {
          siteSpeedSampleRate: 100,
        },
      });

      const trackEvent: TrackEventFunction = (category, action, label) => {
        ReactGA.event({ category, action, label });
      };

      const trackPageView: TrackPageViewFunction = (page) => {
        ReactGA.pageview(page);
      };

      const analyticsFunctions: AnalyticsFunctions = {
        trackEvent,
        trackPageView,
      };
      setGATracker(analyticsFunctions);
    }
  }, [analyticsTrackingId]);

  useEffect(() => {
    if (gaTracker) {
      gaTracker.trackPageView(initialPath);
    }
  }, [gaTracker, initialPath]);

  // default to a no-op
  const value = {
    trackEvent: gaTracker?.trackEvent || (() => {}),
    trackPageView: gaTracker?.trackPageView || (() => {}),
  };
  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  const { trackEvent, trackPageView } = context;
  return { trackEvent, trackPageView };
};
