// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDgPKGH6OVMY0K-j0p23TjIspn9r0w3Gyo",
  authDomain: "memoir-75822.firebaseapp.com",
  projectId: "memoir-75822",
  storageBucket: "memoir-75822.appspot.com",
  messagingSenderId: "334656874256",
  appId: "1:334656874256:web:8b1268c0dd717dc02e6795",
  measurementId: "G-9FMV8D33JG",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
