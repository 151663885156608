import { useEffect, useMemo } from "react";
import { Message } from "__generated__/graphql";
import { MicrophoneIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { gql, useQuery } from "@apollo/client";
import { Card, Flex, ScrollArea } from "@radix-ui/themes";

const TRANSCRIPT_QUERY = gql(`
  query getTranscriptForStory($storyId: String!) {
    getTranscriptForStory(storyId: $storyId) {
      id
      textContent
      speakerRole
      speakerType
      createdAt
    }
  }
`);

const TRANSCRIPT_SUBSCRIPTION = gql(`
  subscription subMessagesByStory($storyId: String!) {
    subMessagesByStory(storyId: $storyId) {
      id
      textContent
      speakerRole
      speakerType
      createdAt
    }
  }
`);

export default function Transcript({
  iid,
  doSubscribe,
  setIsSubscribed,
}: {
  iid: string | undefined;
  doSubscribe: boolean;
  setIsSubscribed: ((isSubscribed: boolean) => void) | null;
}) {
  const { subscribeToMore, data, loading } = useQuery(TRANSCRIPT_QUERY, {
    // @ts-ignore
    variables: { storyId: iid },
    skip: !iid,
  });

  useEffect(() => {
    if (doSubscribe) {
      subscribeToMore({
        document: TRANSCRIPT_SUBSCRIPTION,
        // @ts-ignore
        variables: { storyId: iid },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newFeedItem = subscriptionData.data.subMessagesByStory;
          const res = Object.assign({}, prev, {
            getTranscriptForStory: [newFeedItem, ...prev.getTranscriptForStory],
          });
          return res;
        },
      });
      // Cinjon: TODO I don't think this is the right time to set this. When is?
      setIsSubscribed && setIsSubscribed(true);
    }
  }, [doSubscribe]);

  const groupMessagesIntoRuns = (messages: Partial<Message>[]) => {
    const runs: Partial<Message>[][] = [];
    let currentRun: Partial<Message>[] = [];

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];

      if (i === 0 || message.speakerRole === messages[i - 1].speakerRole) {
        currentRun.push(message);
      } else {
        runs.push(currentRun);
        currentRun = [message];
      }
    }

    if (currentRun.length > 0) {
      runs.push(currentRun);
    }

    return runs;
  };

  const transcriptItemGroups = useMemo(() => {
    if (!data?.getTranscriptForStory) {
      return [];
    }
    return groupMessagesIntoRuns(data.getTranscriptForStory);
  }, [data?.getTranscriptForStory]);

  let transcriptItems = transcriptItemGroups?.map(
    (group: Partial<Message>[], i: number) => {
      const isUser = group[0].speakerType === "HUMAN";
      let icon = <MicrophoneIcon className="h-6 w-6 rounded-full order-1" />;
      let className1 = "flex items-end";
      let className2 = "flex flex-col space-y-1 text-xs max-w-xs mx-2";
      let spanClassName = "px-4 py-2 rounded-lg inline-block";
      let spanClassNameSuffix = "";
      if (isUser) {
        className1 += " justify-end";
        className2 += " order-1 items-end";
        spanClassName += " bg-gray-200 text-gray-600";
        spanClassNameSuffix = " rounded-bl-none";
        icon = <UserCircleIcon className="h-6 w-6 rounded-full order-2" />;
      } else {
        className2 += " order-2 items-start";
        spanClassName += " bg-blue-500 text-white";
        spanClassNameSuffix = " rounded-br-none";
      }

      return (
        <div key={`group-${i}`} className="message">
          <div className={className1}>
            <div className={className2}>
              {group.map((item, j) => {
                let classname;
                if (j === group.length - 1) {
                  classname = spanClassName + spanClassNameSuffix;
                } else {
                  classname = spanClassName;
                }
                return (
                  <div key={`group-${i}-${j}`}>
                    <span className={classname}>{item.textContent}</span>
                  </div>
                );
              })}
            </div>
            {icon}
          </div>
        </div>
      );
    }
  );

  if (loading) {
    return (
      <div className="flex-1 p:2 sm:p-6 justify-between flex flex-col h-full">
        <span> Loading... </span>
      </div>
    );
  }

  return (
    <Card mt={"2"}>
      <ScrollArea
        // type="always"
        scrollbars="vertical"
        style={{ maxHeight: "75vh" }}
        mt={"2"}
      >
        <Flex direction={"column"} gap="2" id="messages">
          {transcriptItems}
        </Flex>
      </ScrollArea>
    </Card>
  );
}
