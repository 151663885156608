import React, { useState, useRef } from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Box, Card, Container, Heading } from "@radix-ui/themes";
import Transcript from "components/Transcript";

const STORY_DETAILS_QUERY = gql`
  query getStory($storyId: String!) {
    getStory(storyId: $storyId) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

//   getAudioChunksForStory(storyId: String!): [String!]!
const GET_AUDIO_CHUNKS_QUERY = gql`
  query getAudioChunksForStory($storyId: String!) {
    getAudioChunksForStory(storyId: $storyId)
  }
`;

function AudioPlayer({ url }: { url: string }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={handlePlayPause}
        className="px-4 py-2 bg-blue-500 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
      >
        {isPlaying ? "Pause" : "Play"}
      </button>
      <audio ref={audioRef} src={url} onEnded={() => setIsPlaying(false)} />
    </div>
  );
}

function AudioChunksComponent({
  data,
  loading,
}: {
  data: any;
  loading: boolean;
}) {
  if (loading) {
    return <p>Loading...</p>;
  }
  if (!data) {
    return <p>No data</p>;
  }

  return (
    <div>
      <h2>Audio Chunks</h2>
      <ul>
        {data.getAudioChunksForStory.map((chunk: string, i: number) => (
          <li key={i}>
            <AudioPlayer url={chunk} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default function CompletedStory() {
  const { storyId } = useParams();

  const {
    loading: storyLoading,
    error: storyError,
    data: storyData,
  } = useQuery(STORY_DETAILS_QUERY, {
    variables: { storyId: storyId },
  });

  const { loading: audioChunksLoading, data: audioChunksData } = useQuery(
    GET_AUDIO_CHUNKS_QUERY,
    {
      variables: { storyId: storyId },
    }
  );

  return (
    <div className="min-h-screen">
      <Container size={{ initial: "1", md: "3" }} p="3">
        <Card>
          {storyData && (
            <Heading size="5" align={"left"}>
              {storyData.getStory.name}
            </Heading>
          )}
          <Box>
            {/* TODO: refactor into component */}
            {storyLoading && <p>Loading...</p>}
            {storyError && <p>Error: {storyError.message}</p>}
            {storyData && (
              <div>
                <p>Created at: {storyData.getStory.createdAt}</p>
                <p>Updated at: {storyData.getStory.updatedAt}</p>
              </div>
            )}
          </Box>

          {/* audio chunks - TODO: refactor into component */}
          <Box>
            <AudioChunksComponent
              data={audioChunksData}
              loading={audioChunksLoading}
            />
          </Box>
          {/* transcript */}
          <Transcript
            iid={storyId}
            doSubscribe={false}
            setIsSubscribed={null}
          />
        </Card>
      </Container>
    </div>
  );
}
