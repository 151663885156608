import React from "react";
import { gql, useQuery } from "@apollo/client";
import {
  Container,
  Card,
  Grid,
  Heading,
  Flex,
  Text,
  Link,
  Button,
} from "@radix-ui/themes";
import { Story } from "__generated__/graphql";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { timeSinceString } from "helpers/prettyTime";
import { useAuth } from "providers/UserProvider";

const GET_STORIES_FOR_USER_QUERY = gql(`
  query GetStoriesForUser($userId: String!) {
    getStoriesForUser(userId: $userId) {
      id
      name
      createdAt
      updatedAt
      botId
      finalized
      public
      longSummary
      shortSummary
    }
  }
`);

function StoryCard({ story }: { story: Story }) {
  const name = story?.shortSummary || story?.name || "Untitled"
  return (
    <Card size={"3"}>
      <Flex gap="1" align="center" direction="column" justify="between">
        <Link href={`/stories/${story.id}`} asChild>
          <RouterLink to={`/stories/${story.id}`}>{name}</RouterLink>
        </Link>
        <Flex direction="column" gap="1">
          <Text size="1" color="gray">
            Created {timeSinceString(story.createdAt)}
          </Text>
          <Text size="1" color="gray">
            Updated {timeSinceString(story.updatedAt)}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}

export default function Stories() {
  const navigate = useNavigate();
  const { user } = useAuth();
  if (!user) {
    navigate("/login");
  }
  
  const { data, loading, error } = useQuery(GET_STORIES_FOR_USER_QUERY, {
    // @ts-ignore
    variables: { userId: user?.uid },
    skip: !user,
  });

  return (
    <div className="min-h-screen">
      <Container size={{ initial: "1", md: "3" }} p={{ initial: "3", md: "3" }}>
        <Flex mb={"3"} mt={"3"} gap="3" direction={"row"} justify={"between"}>
          <Heading size="5" align={"left"}>
            Your Stories
          </Heading>
          <Button>
            <RouterLink to="/stories/new">New Story</RouterLink>
          </Button>
        </Flex>
        <Grid columns={{ initial: "2", md: "3" }} gap="3">
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error.message}</p>}
          {data &&
            data.getStoriesForUser.map((story: Story) => (
              <StoryCard key={story.id} story={story} />
            ))}
        </Grid>
      </Container>
    </div>
  );
}
